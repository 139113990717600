// export const environment = {
//   production: false,
//   maintenanceMode: false,
//   API_URL: 'https://metutors.zeeframes.com/api/', // 'https://stgbackend.metutors.com/api/',
//   chatbotURL: 'https://stgchatbot.metutors.com/',
//   clientUrl: 'http://localhost:4202',
//   clientAiUrl: 'http://localhost:4203',
//   imageURL: 'https://testing.zaptatech.com/storage/uploads/images/',
//   programImage: 'https://testing.zaptatech.com/storage/uploads/program_images/',
//   contactImage: 'https://testing.zaptatech.com/assets/images/contact_images/',
//   fieldOfStudiesImage:
//     'https://testing.zaptatech.com/storage/uploads/field_of_studies/',
//   videoURL: 'https://testing.zaptatech.com/storage/uploads/teacher_videos/',
//   teacherDocs:
//     'https://testing.zaptatech.com/storage/uploads/teacher_documents/',
//   uploadsPath: 'https://testing.zaptatech.com/storage/uploads/',
//   blogsURL: 'https://testing.zaptatech.com/storage/uploads/blogs/',
//   countriesURL: 'https://testing.zaptatech.com/storage/uploads/countries/',
//   cometchat: {
//     region: 'us',
//     appId: '2371424f013c4a5f',
//     authKey: 'bfb8befd54a4c0fb99cf3eb4696ecfa7d7b9031c',
//   },
//   paypal: {
//     authend: { authend: 'sandbox' },
//     returnurl: {
//       tutor: 'https://staging.metutors.com/tutor/settings',
//       student: 'https://staging.metutors.com/student/settings',
//     },
//     appId:
//       'AQ-WLlFPLwYZLo3l2DEsLv76r7_vUGhHNs_duvKL14ppwDrQbWaeCOmRalYI0z_IHT2OnqyTjsxAVFxS',
//   },
//   pusher: {
//     key: '20c25ef0ca119d73ebf9',
//     cluster: 'ap2',
//   },
//   openAIKey: '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',
// };

export const environment = {
  production: false,
  API_URL: 'https://stgbackend.metutors.com/api/',
  clientUrl: 'https://staging.metutors.com',
  clientAiUrl: 'https://staging.metutors.ai',
  imageURL: 'https://stgbackend.metutors.com/uploads/images/',
  videoURL: 'https://stgbackend.metutors.com/uploads/teacher_videos/',
  teacherDocs: 'https://stgbackend.metutors.com/uploads/teacher_documents/',
  programImage:
    'https://stgbackend.metutors.com/storage/uploads/program_images/',
  fieldOfStudiesImage:
    'https://stgbackend.metutors.com/storage/uploads/field_of_studies/',
  uploadsPath: 'https://stgbackend.metutors.com/uploads/',
  blogsURL: 'https://stgbackend.metutors.com/storage/uploads/blogs/',
  countriesURL: 'https://stgbackend.metutors.com/storage/uploads/countries/',
  cookieKey: '61962f35cd3061ba61697935a8b9b34bcb6f03d3',
  chatbotURL: 'https://stgchatbot.metutors.com/',
  contactImage: 'https://stgbackend.metutors.com/assets/images/contact_images/',
  uniprepDocs: 'https://stgbackend.metutors.com/storage/uploads/uniprep/',
  openAIKey: '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',
  maintenanceMode: false,
  cometchat: {
    region: 'us',
    appId: '2371424f013c4a5f',
    authKey: 'bfb8befd54a4c0fb99cf3eb4696ecfa7d7b9031c',
  },
  paypal: {
    authend: { authend: 'sandbox' },
    returnurl: {
      tutor: 'https://staging.metutors.com/tutor/settings',
      student: 'https://staging.metutors.com/student/settings',
    },
    appId:
      'AQ-WLlFPLwYZLo3l2DEsLv76r7_vUGhHNs_duvKL14ppwDrQbWaeCOmRalYI0z_IHT2OnqyTjsxAVFxS',
  },
  pusher: {
    key: '583da172e2da74669b82',
    cluster: 'ap2',
  },
};
